import {
    TIKTOK_PIXEL_ID,
} from './constants'

export function trackViewContent(id, title, price) {
    window.ttq.instance(TIKTOK_PIXEL_ID).track('ViewContent', {
        content_type: 'product',
        currency: 'THB',
        value: price,
        
        contents: [{
            content_id: id,
            quantity: 1,
            description: title,
        }]
    })
    window.fbq('track', 'ViewContent', {
        content_name: title,
        content_ids: [id],
        content_type: 'product',
        value: price,
        currency: 'THB' 
    });          
}

export function trackSearch(productTitle) {
    window.ttq.instance(TIKTOK_PIXEL_ID).track('Search', {
        query: productTitle
    })
    window.fbq('track', 'AddToCart', {
        search_string: productTitle,
    });          
}

export function trackAddToCart(id, title, price, quantity) {
    window.ttq.instance(TIKTOK_PIXEL_ID).track('AddToCart', {
        content_type: 'product',
        currency: 'THB',
        value: price,
        
        contents: [{
            content_id: id,
            content_name: title,
            price: price,
            quantity: quantity,
        }]
    })
    window.fbq('track', 'AddToCart', {
        content_name: title,
        content_ids: [id],
        content_type: 'product',
        value: price,
        currency: 'THB' 
    });          
}

export function trackPlaceAnOrder(amount, skus) {
    window.ttq.instance(TIKTOK_PIXEL_ID).track('PlaceAnOrder', {
        content_type: 'product',
        currency: 'THB',
        value: amount,
        contents: skus.map( s => {
            return {
                content_id: s.variantId,
                quantity: s.quantity,
                description: s.sku,
            }
        })
    })
    window.fbq('track', 'InitiateCheckout', {
        content_ids: skus.map( s => s.variantId),
        num_items: skus.reduce( (acc, s) => acc + s.quantity, 0),
        value: amount,
        currency: 'THB' 
    });          
}

export function trackCompleteRegistration() {
    window.ttq.instance(TIKTOK_PIXEL_ID).track('CompleteRegistration')
    window.fbq('track', 'CompleteRegistration');
}

export function trackCompletePayment(amount) {
    window.ttq.instance(TIKTOK_PIXEL_ID).track('CompletePayment')
    window.fbq('track', 'Purchase', {
        value: amount,
        currency: 'THB' 
    });          
}