import storage from './storage'
import {
    STORAGE_KEYS
} from './constants'

const cartVersion = '1.1.1'
var products = []

export function initCartDB() {

    const version = storage.get(STORAGE_KEYS.cartVersion)
    if (cartVersion !== version) {
        storeCarts()
        storage.set(STORAGE_KEYS.cartVersion, cartVersion)
    }
    syncProductsLocal()
}

function syncProductsLocal() {
    const data = storage.get(STORAGE_KEYS.carts)
    if (data)
        products = JSON.parse(storage.get(STORAGE_KEYS.carts))
}

function storeCarts() {
    storage.setObj(STORAGE_KEYS.carts, products)
}

export function addCart(variant) {
    syncProductsLocal()
    const {
        productTitle,
        productImage,
        productId,
        metafields
    } = variant
    const existProduct = products.find((product) => {
        return product.id === productId
    })

    if (existProduct) {
        let variants = existProduct.variants
        let existVariant = variants.find((v) => {
            const {
                variantId
            } = v

            if (variantId)
                return v.variantId === variant.variantId
            else
                return v.sku === variant.sku

        })

        if (existVariant) {
            const {
                quantity: oriQuantity,
            } = existVariant
            const {
                quantity
            } = variant
            const qty = Number(quantity) + Number(oriQuantity)
            existVariant.quantity = qty
        } else {
            variants.unshift(variant)
            existProduct.variants = variants
        }
    } else {
        let {
            seller_member_id
        } = metafields
        //没有seller_member_id,不加入购物车
        if (!seller_member_id)
            seller_member_id = "beericher-default-seller"
        const product = {
            id: productId,
            title: productTitle,
            image: productImage,
            seller_member_id
        }
        const variants = [variant]
        product.variants = variants
        products.unshift(product)
    }
    storeCarts()
}

export function queryCarts() {
    return products
}

export function cartCount() {
    return products.length
}

export function removeCart(variant) {

    syncProductsLocal()

    const product = products.find((item) => {
        return item.id === variant.productId
    })

    if (!product)
        return

    const {
        variants
    } = product
    const index = variants.findIndex((v) => {

        const {
            variantId
        } = v

        if (variantId)
            return v.variantId === variant.variantId
        else
            return v.sku === variant.sku
    })
    variants.splice(index, 1)

    if (variants.length === 0) {
        const productIndex = products.indexOf(product)
        products.splice(productIndex, 1)
    }

    storeCarts()
}

export function updateQuantity(variant, num) {
    syncProductsLocal()

    const product = products.find((item) => {
        return item.id === variant.productId
    })

    if (!product)
        return
    const {
        variants
    } = product
    const updateVariant = variants.find((v) => {
        const {
            variantId
        } = v

        if (variantId)
            return v.variantId === variant.variantId
        else
            return v.sku === variant.sku
    })

    updateVariant.quantity = num
    storeCarts()
}

export function clearCart() {
    products = []
    storeCarts()
}

export function removeProduct(productId) {
    const index = products.findIndex((item) => {
        return item.id != productId
    })
    products.splice(index, 1)
    // storeCarts()
}

export function clearProductBought(vs) {

    syncProductsLocal()

    vs.forEach(variant => {
        const product = products.find((item) => {
            return item.id === variant.productId
        })

        if (!product)
            return

        const {
            variants
        } = product
        const index = variants.findIndex((v) => {
            const {
                variantId
            } = v

            if (variantId)
                return v.variantId === variant.variantId
            else
                return v.sku === variant.sku
        })
        variants.splice(index, 1)

        if (variants.length === 0) {
            const productIndex = products.indexOf(product)
            products.splice(productIndex, 1)
        }
    });

    storeCarts()
}