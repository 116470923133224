<template>
  <div>
    <div style="padding-bottom: 65px; font-size: 0.9rem">
      <div class="address-item" @click="chooseAddress()">
        <van-image v-if="!address" width="30" height="30" :src="iconAdd" />
        <van-icon v-if="address" name="location-o" size="20px" />
        <div class="address-content">
          <div v-if="!address">{{ $t("Add new address") }}</div>
          <div v-if="address">{{ address.name }} {{ address.phone }}</div>
          <div v-if="address">
            {{ address.province }} {{ address.city }} {{ address.address1 }}
          </div>
        </div>
        <van-icon name="arrow" />
      </div>

      <div>
        <div
          v-for="(seller, index) in sellers"
          :key="seller.seller_member_id"
          class="seller-item"
        >
          <div class="order-seller-title van-hairline--top-bottom">
            {{ $t("订单") }}{{ index + 1 }}:{{ seller.seller_member_id }}
          </div>

          <div
            v-for="product in seller.products"
            :key="product.id"
            class="product-item"
          >
            <div class="flex-item">
              <van-image width="60" height="60" :src="product.image" />
              <span
                style="display: inline-block; padding-left: 10px; flex: 1"
                >{{ product.title }}</span
              >
            </div>

            <div
              v-for="variant in product.variants"
              :key="variant.variantId"
              style="padding: 15px 0px 0px"
            >
              <div class="variant-item">
                <van-image
                  width="25"
                  height="25"
                  :src="variant.image"
                  radius="2"
                  class="product-image"
                />

                <span class="product-title variant-sku">
                  {{ formatThValue(variant.sku) }}
                </span>
              </div>
              <div class="variant-item">
                <div>
                  <span class="color-thb"
                    >฿{{
                      product.priceTHB ? product.priceTHB : variant.price
                    }}</span
                  >
                  <span
                    v-if="
                      product.priceCNY ? product.priceCNY : variant.cny_price
                    "
                  >
                    ¥{{
                      product.priceCNY ? product.priceCNY : variant.cny_price
                    }}
                  </span>
                </div>
                <span
                  style="
                    display: inline-block;
                    flex: 1;
                    text-align: right;
                    padding: 10px 0px;
                  "
                >
                  x{{ variant.quantity }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="pay-now mobile-fixed-theme">
      <div style="flex: 1; text-align: right">
        <span class="pay-total"> {{ $t("Total") }}: </span>
        <div
          style="display: inline-block; padding: 0px 5px"
          v-if="totalPriceTHB"
        >
          <span style="color: #ee502f">฿{{ totalPriceTHB }}</span>
          <span
            style="display: inline-block; padding-left: 5px"
            v-if="totalPriceCNY"
            >¥{{ totalPriceCNY }}</span
          >
        </div>
      </div>

      <van-button
        @click="doSubmit"
        color="#EE502F"
        round
        size="small"
        style="margin: 0px 10px; font-size: 0.9rem"
        :loading="submitLoading"
        :disabled="submitLoading"
      >
        {{ $t("Submit") }}
      </van-button>
    </div>
  </div>
</template>

<script>
import iconAdd from "@/assets/images/icon_add.png";
import { Image, Icon, Cell, CellGroup, Button, Toast } from "vant";
import { defaultAddress, batchDraftOrderCreate } from "./service";
import { getShopifyCustomer, getExchangeRate } from "@/utils/token";
import { formatPriceCeil, formatThValue } from "@/utils/utils";
import { clearProductBought } from "@/utils/cart.js";
import { getChatWoot } from "@/utils/storage";
import { VENDOR, SHOPIFY_TAG, SUCCESS } from "@/utils/constants";
import { trackPlaceAnOrder } from "@/utils/statistic";

export default {
  components: {
    [Image.name]: Image,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
  },
  data() {
    return {
      iconAdd,
      address: null,
      submitLoading: false,
      orderId: null,
      draftOrder: null,
      isAli1688: false,
      totalPriceCNY: 0,
      totalPriceTHB: 0,
      sellers: [],
    };
  },
  methods: {
    formatThValue(value) {
      return formatThValue(value);
    },
    calculateTotalPrice() {
      let totalPriceCNY = 0;
      let totalPriceTHB = 0;

      this.sellers.forEach((seller) => {
        const { products } = seller;

        products.forEach((product) => {
          const { variants } = product;

          let totalQuantity = 0;
          variants.forEach((v) => {
            const { quantity } = v;
            totalQuantity += quantity;
          });

          const appliedDiscountVariant = variants[0];
          const { price, priceRMB: discountPriceRMB } =
            this.getAppliedDiscountByProduct(
              appliedDiscountVariant,
              totalQuantity
            );

          variants.forEach((variant) => {
            const { quantity } = variant;
            const sumTHB = this.NumberMul(price, quantity);
            const sumCNY = this.NumberMul(discountPriceRMB, quantity);
            totalPriceTHB = this.NumberAdd(totalPriceTHB, sumTHB);
            totalPriceCNY = this.NumberAdd(totalPriceCNY, sumCNY);
          });

          product.priceTHB = price;
          product.priceCNY = discountPriceRMB;
        });
      });

      this.totalPriceTHB = formatPriceCeil(totalPriceTHB);
      this.totalPriceCNY = formatPriceCeil(totalPriceCNY);
    },
    async doSubmit() {
      if (!this.address) {
        Toast.fail(this.$t("请先选择收件地址"));
        return;
      }

      let customAttributes = [];
      const chatwoot = getChatWoot();
      if (chatwoot) {
        const { chatChannel, chatConversationId, chatUserId } = chatwoot;
        customAttributes.push({
          key: "chatwoot",
          value: `chatChannel=${chatChannel}&chatConversationId=${chatConversationId}&chatUserId=${chatUserId}`,
        });
      }

      const orderPayloads = [];
      let isAli1688 = false;
      this.sellers.forEach((seller) => {
        const { products } = seller;

        let totalPrice = 0;
        let lineItems = [];
        products.forEach((product) => {
          const { variants } = product;

          let totalQuantity = 0;
          variants.forEach((v) => {
            const { quantity } = v;
            totalQuantity += quantity;
          });

          const appliedDiscountVariant = variants[0];
          const { appliedDiscount, price } = this.getAppliedDiscountByProduct(
            appliedDiscountVariant,
            totalQuantity
          );

          variants.forEach((variant) => {
            const {
              quantity,
              variantId,
              sku,
              skuOri,
              image,
              productImage,
              vendor,
              productId,
              productTitle,
              productTitleOri,
            } = variant;
            const sumTHB = this.NumberMul(price, quantity);
            totalPrice = this.NumberAdd(totalPrice, sumTHB);

            isAli1688 = vendor == VENDOR.ali1688;

            let line;
            if (variantId)
              line = {
                quantity,
                variantId,
                // productId,
                customAttributes: [
                  {
                    key: "image",
                    value: image ? image : productImage,
                  },
                ],
              };
            else
              line = {
                title: productTitle,
                originalUnitPrice: variant.price,
                quantity,
                // productId,
                sku,
                requiresShipping: true,
                customAttributes: [
                  {
                    key: "title",
                    value: productTitleOri,
                  },
                  {
                    key: "sku",
                    value: skuOri,
                  },
                  {
                    key: "productId",
                    value: productId,
                  },
                  {
                    key: "vendor",
                    value: vendor,
                  },
                  {
                    key: "image",
                    value: image ? image : productImage,
                  },
                ],
              };

            if (appliedDiscount) {
              line.appliedDiscount = appliedDiscount;
            }
            lineItems.push(line);
          });
        });

        const {
          address1,
          address2,
          city,
          country,
          firstName,
          name,
          phone,
          province,
          zip,
        } = this.address;

        const orderAddress = {
          address1,
          address2,
          city,
          country,
          firstName,
          phone,
          province,
          zip,
        };
        const note = `汇率${getExchangeRate()},泰铢总金额:${totalPrice}`;
        const orderPayload = {
          note,
          lineItems,
          billingAddress: orderAddress,
          shippingAddress: orderAddress,
          tags: isAli1688 ? SHOPIFY_TAG.cross_border : SHOPIFY_TAG.local,
        };
        if (customAttributes.length !== 0) {
          orderPayload.customAttributes = customAttributes;
        }
        orderPayloads.push(orderPayload);
      });

      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.$t("加载中..."),
      });
      const res = await batchDraftOrderCreate(orderPayloads);
      Toast.clear();

      if (res && res.length !== 0) {
        const { data } = res[0];
        const { draftOrderCreate } = data;
        const { userErrors } = draftOrderCreate;
        if (userErrors && userErrors.length !== 0) {
          let message;
          userErrors.forEach((userError) => {
            message += "," + userError.message;
          });
          Toast.fail(message);
        } else {
          //下单成功清理购物车
          let vs = [];
          this.sellers.forEach((s) => {
            s.products.forEach((p) => {
              p.variants.forEach((v) => {
                vs.push(v);
              });
            });
          });
          clearProductBought(vs);
          trackPlaceAnOrder(this.totalPriceTHB, vs);
          this.$router.replace({
            name: "success",
            query: {
              successType: SUCCESS.ORDER,
            },
          });
        }
      }
    },
    getAppliedDiscountByProduct(variant, totalQuantity) {
      const { quantity, price, metafields, cny_price } = variant;
      const { price_ranges } = metafields;

      if (metafields.sku_price_type != "rangePrice") {
        return { price, priceRMB: cny_price };
      }

      const { local, rmb } = JSON.parse(price_ranges);
      let priceRangeTHB = local.sort((a, b) => {
        return a.startAmount - b.startAmount;
      });

      let priceRangeRMB = rmb.sort((a, b) => {
        return a.startAmount - b.startAmount;
      });
      let range;
      let disountPriceRMB;
      let disountPrice = (function (prices, quantity) {
        for (let index = 0; index < prices.length; index++) {
          const { startAmount, price } = prices[index];
          const { price: rmbPrice } = priceRangeRMB[index];
          if (index >= prices.length - 1) {
            range = `≥${startAmount}件,${price}THB`;
            disountPriceRMB = rmbPrice;
            return price;
          } else {
            const { startAmount: s } = prices[index + 1];
            if (quantity >= startAmount && quantity < s) {
              range = `${startAmount}-${s}件,${price}THB`;
              disountPriceRMB = rmbPrice;
              return price;
            }
          }
        }
      })(priceRangeTHB, totalQuantity ? totalQuantity : quantity);

      const amount = parseFloat(this.NumberMinus(price, disountPrice));
      const title = `${range}`;
      const description = `${range}`;

      const appliedDiscount = {
        value: amount,
        title,
        amount,
        valueType: "FIXED_AMOUNT",
        description,
      };
      return {
        appliedDiscount,
        price: disountPrice,
        priceRMB: disountPriceRMB,
      };
    },
    getAppliedDiscount() {
      let discountProducts = [];
      let productIds = [];
      let appliedDiscounts = {};
      this.lines.forEach((line) => {
        const { metafields } = line;
        if (!metafields || metafields.sku_price_type != "rangePrice") {
          return true;
        }

        const { price_ranges } = metafields;
        const { quantity, productId, price } = line;
        const index = productIds.indexOf(productId);
        if (index >= 0) {
          const discount = discountProducts[index];
          discount.quantity = discount.quantity + quantity;
        } else {
          productIds.push(productId);
          discountProducts.push({ productId, quantity, price, price_ranges });
        }
      });

      discountProducts.forEach((discount) => {
        const { productId, quantity, price, price_ranges } = discount;
        const { rmb } = JSON.parse(price_ranges);
        let priceRange = rmb.sort((a, b) => {
          return a.startAmount - b.startAmount;
        });
        let range;
        let disountPrice = (function (rmb, quantity) {
          for (let index = 0; index < rmb.length; index++) {
            const { startAmount, price } = rmb[index];
            if (index >= rmb.length - 1) {
              range = `≥${startAmount}件,${price}元`;
              return price;
            } else {
              const { startAmount: s } = rmb[index + 1];
              if (quantity >= startAmount && quantity < s) {
                range = `${startAmount}-${s}件,${price}元`;
                return price;
              }
            }
          }
        })(priceRange, quantity);
        const amount = parseFloat(this.NumberMinus(price, disountPrice));
        const title = `${range}`;
        const description = `${range}`;

        const appliedDiscount = {
          value: amount,
          title,
          amount,
          valueType: "FIXED_AMOUNT",
          description,
        };
        appliedDiscounts[productId] = appliedDiscount;
      });

      this.lines.forEach((line) => {
        const { productId } = line;
        const appliedDiscount = appliedDiscounts[productId];
        if (appliedDiscount) line.appliedDiscount = appliedDiscount;
      });
    },
    chooseAddress() {
      this.$store.commit("setOrderId", this.orderId);
      this.$router.push({
        name: "addresses",
        query: {
          origin: "order",
        },
      });
    },
    async defaultAddress() {
      const shopifyCustomer = getShopifyCustomer();
      if (!shopifyCustomer) {
        Toast.fail("customer access token invalid");
        return;
      }

      const { shopifyCustAccessToken } = shopifyCustomer;

      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.$t("加载中..."),
      });
      const res = await defaultAddress(shopifyCustAccessToken);
      Toast.clear();
      const { defaultAddress: address } = res.data.customer;
      if (address) {
        this.address = address;
      }
    },
  },
  mounted() {
    const { checkedSellers: sellers, selectAddress } = this.$store.state;

    this.sellers = sellers;

    this.calculateTotalPrice();
    if (selectAddress) {
      this.address = selectAddress;
    } else {
      this.defaultAddress();
    }
  },
};
</script>

<style lang="less" scoped>
@primaryColor: #ee502f;

.address-item {
  background-color: white;
  display: flex;
  padding: 15px;
  align-items: center;
}

.address-content {
  flex: 1;
  padding: 0px 10px;
}

.pay-now {
  position: fixed;
  height: 45px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: white;
  display: flex;
  font-size: 0.9rem;
  align-items: center;
}

:deep(.van-button--block) {
  width: 180px;
  display: inline-block;
}

.pay-total {
  color: @primaryColor;
}

.order-seller-title {
  padding: 15px;
}

.seller-item {
  background-color: white;
  margin: 10px 0px;
}

.product-item {
  padding: 15px;
}

.flex-item {
  display: flex;
  align-items: center;
}

.variant-item {
  display: flex;
  align-items: center;
}

.product-image {
  margin: 0px 10px;
}

.variant-sku {
  background-color: #f9f9f9;
  border-radius: 5px;
  display: inline-block;
  padding: 5px;
  flex: 1;
}

.color-thb {
  display: inline-block;
  margin-right: 5px;
  color: red;
  padding-left: 26px;
}
</style>