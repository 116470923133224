import gql from "graphql-tag";

export const defaultAddress = gql `
query defaultAddress($customerAccessToken: String!){
    customer(customerAccessToken: $customerAccessToken) {
        defaultAddress {
            id
            firstName
            zip
            province
            phone
            name
            city
            country
            address1
            address2
        }
    }
}
`