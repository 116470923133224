import axios from '@/utils/request';
import {
    query,
} from "@/utils/apollo-utils.js"
import {
    defaultAddress as defaultAddressGql,
} from "./gqls.js"

export async function draftOrderCreate(payload) {
    return axios.post('shopify/draftOrderCreate', payload);
}

export async function defaultAddress(customerAccessToken) {
    return query(defaultAddressGql, {
        customerAccessToken
    })
}

export async function draftOrderUpdate(payload) {
    return axios.post('shopify/draftOrderUpdate', payload);
}

export async function uploadSlip(payload) {
    return axios.post('shopify/uploadSlip', payload, {
        'Content-Type': 'multipart/form-data'
    });
}

export async function batchDraftOrderCreate(payload) {
    return axios.post('shopify/batchDraftOrderCreate', payload);
}
